export const getImageList = (t) => [
  {
    imageName: 'banner_tradesman',
    imageAlt: t('ImageAlts:sliderTadesman'),
  },
  {
    imageName: 'banner_accounts',
    imageAlt: t('ImageAlts:sliderAccounts'),
  },
  {
    imageName: 'banner_fashion',
    imageAlt: t('ImageAlts:sliderFashion'),
  },
  {
    imageName: 'banner_young_couple',
    imageAlt: t('ImageAlts:sliderYoungCouple'),
  },
]
