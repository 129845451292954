/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Button, Text, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import C from '../../constants'

const FreeTrialBanner = () => {
  const { t } = useTranslation()

  const handleOnClick = () => {
    window.open(C.links.signupLink)
  }

  return (
    <Flex
      p={5}
      bg="lynxWhite"
      sx={{
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text pb={4} variant="heading">
        {t('FreeTrialBanner:headerText')}
      </Text>
      <Text variant="detail">{t('FreeTrialBanner:detailCC')}</Text>
      <Text pb={4} variant="detail">
        {t('FreeTrialBanner:detailPrivacy')}
      </Text>
      <Button variant="primary" px={4} py={2} onClick={handleOnClick}>
        {t('FreeTrialBanner:buttonText')}
      </Button>
    </Flex>
  )
}

export default FreeTrialBanner
