/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { jsx } from 'theme-ui'

const SliderTopText = ({
  sliderHeadingText,
  sliderDetailText,
  opacity = 1,
}) => (
  <div
    sx={{
      position: 'absolute',
      top: ['13%', '13%', '15%', '15%'],
      left: [4, 4, 5, 5],
      zIndex: 1,
      whiteSpace: 'pre-wrap',
      opacity: opacity,
      transition: 'opacity 500ms ease-in-out',
      display: ['none', 'block', 'block', 'block'],
    }}
  >
    <h1
      sx={{
        width: [250, 300, 400, 500],
        fontFamily: 'body',
        fontWeight: 'extraBold',
        color: 'charcoal',
        fontSize: [4, 5, 6, 7],
      }}
    >
      {sliderHeadingText}
    </h1>
    <p
      sx={{
        width: [250, 300, 400, 500],
        fontFamily: 'body',
        fontWeight: 'light',
        color: 'charcoal',
        fontSize: [2, 3, 5, 5],
      }}
    >
      {sliderDetailText}
    </p>
  </div>
)

export default SliderTopText
