import React, { useState } from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import SliderTopText from './SliderTopText'
import Image from '../Image'

const ImageSlider = ({ imageList = [], ...props }) => {
  const [textOpacity, setTextOpacity] = useState(1)
  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1500,
    beforeChange: () => setTextOpacity(0),
    afterChange: () => setTextOpacity(1),
  }
  if (imageList.length <= 0) return null
  return (
    <>
      <SliderTopText {...props} opacity={textOpacity} />
      <Slider {...sliderSettings}>
        {imageList.map((image) => (
          <Image
            key={image.imageName}
            imageName={image.imageName}
            alt={image.imageAlt}
          />
        ))}
      </Slider>
    </>
  )
}

export default ImageSlider
