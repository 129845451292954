/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Text, jsx } from 'theme-ui'
import Img from 'gatsby-image'

import { LinkWrapper } from '../../components/Link'

const PostLink = ({ title, slug, image, imageAlt }) => (
  <Flex
    p={2}
    m={2}
    sx={{
      flexDirection: 'column',
      flexGrow: 1,
      minWidth: ['100%', '100%', '250px', '335px'],
      maxWidth: ['100%', '90%', '300px', '450px'],
      borderColor: 'summerSky',
    }}
  >
    <Img
      fluid={image.childImageSharp.fluid}
      alt={imageAlt}
      style={{ width: '100%' }}
    />
    <LinkWrapper href={slug}>
      <Text variant="linkHeader" sx={{ wordWrap: 'normal' }}>
        {title || slug}
      </Text>
    </LinkWrapper>
  </Flex>
)

export default PostLink
