import { useStaticQuery, graphql } from 'gatsby'

const useBlogPostData = (limit) => {
  const data = useStaticQuery(graphql`
    query {
      allMdxBlogPost(sort: { fields: date, order: DESC }) {
        nodes {
          title
          date(formatString: "Do MMMM YYYY")
          image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          imageAlt
          slug
        }
      }
    }
  `)

  const posts = data?.allMdxBlogPost?.nodes || []

  return posts.slice(0, limit)
}

export default useBlogPostData
