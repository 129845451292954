/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Flex, jsx } from 'theme-ui'

import Image from '../Image'

const commonStyle = { flex: 1, minWidth: '300px', padding: 3 }

const ImageBlock = ({ imageName, imageAlt }) => (
  <Flex sx={commonStyle}>
    <Image imageName={imageName} alt={imageAlt} />
  </Flex>
)

const Banner = ({ children, textOrientation = 'left', ...props }) => {
  const targetRef = useRef()
  const [width, setWidth] = useState(0)
  const [orientation, setOrientation] = useState(textOrientation)

  useLayoutEffect(() => {
    if (targetRef.current) {
      setWidth(targetRef.current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    width <= 640 ? setOrientation('left') : setOrientation(textOrientation)
  }, [width, orientation, textOrientation])

  return (
    <Flex
      ref={targetRef}
      p={[0, 0, 4, 4]}
      bg="transparent"
      sx={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}
    >
      {orientation === 'left' ? (
        <>
          <Flex sx={commonStyle}>{children}</Flex>
          <ImageBlock {...props} />
        </>
      ) : (
        <>
          <ImageBlock {...props} />
          <Flex sx={commonStyle}>{children}</Flex>
        </>
      )}
    </Flex>
  )
}

export default Banner
