/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, jsx } from 'theme-ui'

import PostLink from './post-link'

const PostList = ({ posts }) => (
  <Flex
    sx={{
      flex: 1,
      justifyContent: 'center',
      flexWrap: 'wrap',
    }}
  >
    {posts.map((node) => (
      <PostLink key={node.slug} {...node} />
    ))}
  </Flex>
)

export default PostList
