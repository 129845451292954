/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { Flex, Box, Text, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'

import SEO from '../components/Seo'
import NavBar from '../components/NavBar'
import ImageSlider from '../components/ImageSlider'
import Features from '../components/Features'
import Button from '../components/Button'
import Banner from '../components/Banner'
import TextBlock from '../components/TextBlock'
import FreeTrialBanner from '../components/FreeTrialBanner'
import LatestArticles from '../components/LatestArticles'
import Footer from '../components/Footer'

import { getImageList } from '../components/ImageSlider/imageList'

import '../i18n/i18n'

const IndexPage = () => {
  const { t } = useTranslation()
  const sliderImageList = getImageList(t)
  return (
    <Box alignItems="center">
      <SEO title="Home" />
      <NavBar />
      <Box sx={{ width: '100%' }}>
        <ImageSlider
          sliderHeadingText={t('Global:takeControlText')}
          sliderDetailText={t('HomePage:bannerDetailText')}
          imageList={sliderImageList}
        />
      </Box>

      <Flex
        bg="lynxWhite50"
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: [0, 0, '60px', '60px'],
          paddingX: [0, 4, 4, '50px'],
        }}
      >
        <Flex
          flexDirection="column"
          p={3}
          mt={4}
          pb={5}
          sx={{
            display: ['block', 'none', 'none', 'none'],
          }}
        >
          <Box
            sx={{
              marginBottom: 4,
            }}
          >
            <Text variant="heading">{t('Global:takeControlText')}</Text>
          </Box>
          <Text variant="detail">{t('HomePage:bannerDetailText')}</Text>
        </Flex>

        <Features featuredOnly={true} />
        <Flex
          p={4}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{ margin: 2 }}
            variant="primary"
            onClick={() => navigate('/features/')}
          >
            {t('HomePage:showAllFeatures')}
          </Button>
        </Flex>
        <Banner
          imageName="holding-tablet-04"
          imageAlt={t('ImageAlts:imageAlt1')}
          textOrientation="right"
        >
          <TextBlock
            heading={t('Banners:heading1')}
            textBeforeLink={t('Banners:detail1')}
          />
        </Banner>
        <Banner
          imageName="woman-desk-smiling"
          imageAlt={t('ImageAlts:imageAlt2')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('Banners:heading2')}
            textBeforeLink={t('Banners:detail2')}
          />
        </Banner>
        <Banner
          imageName="desk-ipad-02"
          imageAlt={t('ImageAlts:imageAlt3')}
          textOrientation="right"
        >
          <TextBlock
            heading={t('Banners:heading3')}
            textBeforeLink={t('Banners:detail3')}
          />
        </Banner>
      </Flex>
      <FreeTrialBanner />
      <Flex
        bg="lynxWhite50"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LatestArticles />
      </Flex>
      <Footer />
    </Box>
  )
}

export default IndexPage
