/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Text, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import useBlogPostData from '../../hooks/useBlogPostData'
import PostList from '../../gatsby-theme-blog/components/post-list'

const LatestArticles = () => {
  const { t } = useTranslation()
  const posts = useBlogPostData(6)
  return (
    <Flex
      p={[2, 2, 4, 5]}
      sx={{
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '1600px',
      }}
    >
      <Text pb={4} variant="heading">
        {t('HomePage:latestArticles')}
      </Text>
      <PostList posts={posts} />
    </Flex>
  )
}

export default LatestArticles
